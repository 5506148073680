import { Link } from 'react-router-dom';
import wurd from 'wurd-react';

import store from 'store';
import { getLangText, getTypePrice, getPrice } from 'utils/ui';

import Button from 'components/button';
import ListItemCard from 'components/list-item-card';
import FeatureList from 'components/feature-list';
import Annotations from './annotations';


const cms = wurd.block('siteDetail');


export default function UnitTypeCard({
  unitType,
  site
}) {
  const { invoicePeriod } = store.get('settings');
  const available = unitType.availability.available > 0;

  return (
    <>
      <Annotations
        position="row_top-right"
        annotations={unitType.annotations}
      />

      <ListItemCard
        image={unitType.image}
        imageFit={unitType.imageFit}
      >

        <h3 className="text-truncate">{getLangText(unitType.title)}</h3>
        <h4 className="text-truncate text-muted fw-light fs-6">{getLangText(unitType.subtitle)}</h4>

        <FeatureList iconsOnly tags={unitType.tags} className="flex-1" />

        <div className="d-flex align-items-center justify-content-between gap-1 mt-2">
          <div className="d-flex flex-wrap align-items-baseline">
            <div>
              <Annotations
                position="price_above"
                annotations={unitType.annotations}
              />
              <div>
                {unitType.price_before && (
                  <span className="fs-5 ls-1 text-muted"><s>{getPrice(unitType.price_before)}</s> </span>
                )}
                <span className="fs-4 fw-600 ls-1">{getTypePrice(unitType)}</span>
                <small className="text-muted fw-light"><cms.Text id={`per_${invoicePeriod}`} /></small>
              </div>
              <Annotations
                position="price_below"
                annotations={unitType.annotations}
              />
            </div>
          </div>

          {available ? (
            <Button icon="next" as={Link} to={`/sites/${site.code}/${unitType.code}`} className="px-2 px-md-4">
              <cms.Text id="chooseUnit" />
            </Button>
          ) : (
            <div data-unit-type={unitType.code}><cms.Text id="notAvailable" /></div>
          )}
        </div>
      </ListItemCard>
    </>
  );
}