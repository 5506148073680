import { useQuery } from 'react-query';
import styled from '@emotion/styled';
import { useSearchParams } from 'react-router-dom';

import * as actions from 'actions';

import Loader from 'components/loader';
import UnitTypeCard from 'components/site-detail/unit-type-card';

const Main = styled.div`
  padding: 1rem;
`;


export default function UnitTypeRowPreview() {
  const [searchParams] = useSearchParams();
  const siteCode = searchParams.get('site');
  const unitTypeCode = searchParams.get('unitType');

  const { data: site } = useQuery('site', () => actions.sites.get(siteCode));
  const { data: unitType } = useQuery('unitType', () => actions.unitTypes.get(siteCode, unitTypeCode));

  if (!site || !unitType) return (
    <Loader />
  );

  return (
    <Main>
      <UnitTypeCard
        site={site}
        unitType={unitType}
      />
    </Main>
  );
}
